@import 'typography';

%height-max { height: 100%; }

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html { @extend %height-max; }

body {
  @extend %height-max;

  overflow-y: hidden !important;
  font-family: $rubik;

}

#root { 
  width: 100%; @extend %height-max; 
  overflow: hidden;
}

a, a:hover { text-decoration: unset; }

ul, li { list-style-type: none; }

h1 {
  font-size: 2.813rem !important;
  font-weight: 500 !important;
  color: $primary;
  margin: 0;
}

h2 {
  font-size: 2rem !important;
  font-weight: 500 !important;
  color: $primary;
  margin: 0;
}

h3 {
  font-size: 1.75rem !important;
  font-weight: 500 !important;
  color: $primary;
  margin: 0;
}

h5 {
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  color: $primary;
  margin: 0;
}

p {
  font-size: 1rem !important;
  color: $fontSecondary !important;
  font-weight: 400;
  margin: 0;
}

label {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  color: $fontTertiary !important;
}

.MuiPaginationItem-root {
  background-color: $white !important;
  color: $primary !important;

  &.Mui-selected { background-color: $primaryLight !important; }
  &.MuiPaginationItem-previousNext { border: 1px solid $primary !important; }
}