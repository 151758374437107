@import '../../../../../../styles/base/color';

.navbar-sidebar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: #FFF;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-bars {
    margin: 1rem;
    font-size: 1rem;
    background: none;
}

.notification {
    &-menu {
        margin: 1rem;
        font-size: 1.5rem;
    }

    &-icon {
        position: relative;
    }

    &-number {
        position: absolute;
        top: 10px;
        right: 7px;
        font-weight: 700;
        padding: 0 6px;
        border-radius: 50%;
        background: $danger;
        color: $white;
        font-size: 0.675rem;
    }
}

.nav-menu {
    background: $gradientGreen;
    height: 80px;
    width: 250px;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 999;
    transition: 850ms;
    overflow-y: auto;

    &-notifications {
        background: $off-white;
        width: 350px;
        height: 100vh;
        display: flex;
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 999;
        transition: 850ms;
        padding: 10px;
        overflow-y: auto;

        &.active {
            right: 0;
            transition: 350ms;
        }


    }

    &-items { padding: 15px; }

    &.active {
        left: 0;
        transition: 350ms;
    }

    .nav-text{
        text-decoration: none;
        color: $white;
        font-size: 0.875rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: 10px;

        &.active, &:hover {
            color: $primary !important;

            li { background-color: $white; } 
        }

        li {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            list-style: none;
            border-radius: 8px;

            &:hover .aviso { color: #215732; }
        }
    }
}

.navbar-toggle {
    background: transparent;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}